import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import {
  useIntl,
  FormattedMessage,
  FormattedHTMLMessage
} from 'gatsby-plugin-intl'
import styleVariables from '~/layouts/variables.scss'

import GetWindowDimensions from '~/components/00-global/util/getWindowDimensions'
import { Scroll } from '~/components/00-global/Animation/Animation'

import './handmade.scss'

const Handmade = () => {
  const intl = useIntl()
  const { imageHandmadeBackground, imageFlagGermany } = useStaticQuery(graphql`
    query {
      imageHandmadeBackground: file(
        relativePath: { eq: "image-handmade-background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      imageFlagGermany: file(relativePath: { eq: "image-flag-germany.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const md =
    typeof document !== `undefined` ? styleVariables.md.replace(/\D+/, '') : ''
  const { windowWidth } = GetWindowDimensions()
  return (
    <section id='handmade'>
      <BackgroundImage
        className='background-image'
        fluid={imageHandmadeBackground.childImageSharp.fluid}
        alt={intl.formatMessage({ id: 'home.handmade.imageBackground' })}
        style={
          windowWidth < md
            ? {
                backgroundPosition: `85% 0`
              }
            : { backgroundPosition: `100% 0` }
        }
      >
        <Container>
          <div className='background'>
            <div className='content'>
              <div className='flag'>
                <span role='img' aria-label='germany-flag'>
                  🇩🇪
                </span>
              </div>
              <Scroll>
                <h2 className='h2-xxl'>
                  <FormattedHTMLMessage id='home.handmade.title' />
                </h2>
              </Scroll>
              <Scroll>
                <p className='label'>
                  <span>
                    <FormattedMessage id='home.handmade.text' />
                  </span>
                </p>
              </Scroll>
            </div>
          </div>
        </Container>
      </BackgroundImage>
    </section>
  )
}

export default Handmade
