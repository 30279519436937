import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Button from '~/components/00-global/Button/Button'
import ImageStripe from '~/images/image-stripe.svg'
import ImageBiersafeL from '~/images/image-biersafe-l.png'
import ImageBiersafeM from '~/images/image-biersafe-m.png'
import ImageBiersafeS from '~/images/image-biersafe-s.png'
import { Scroll } from '~/components/00-global/Animation/Animation'

import {
  useIntl,
  FormattedMessage,
  FormattedHTMLMessage,
  Link
} from 'gatsby-plugin-intl'

import './sizes.scss'

const Sizes = () => {
  const intl = useIntl()
  const {
    imageFangtechnik,
    imageScaffold,
    imageZubehoer,
    imageBiersafeL,
    imageBiersafeM,
    imageBiersafeS
  } = useStaticQuery(graphql`
    query {
      imageFangtechnik: file(relativePath: { eq: "image-fangtechnik.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageScaffold: file(relativePath: { eq: "image-story-three.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageZubehoer: file(relativePath: { eq: "image-pimpyourbiersafe.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageBiersafeL: file(relativePath: { eq: "image-biersafe-l.png" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageBiersafeM: file(relativePath: { eq: "image-biersafe-m.png" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageBiersafeS: file(relativePath: { eq: "image-biersafe-s.png" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const images = [ImageBiersafeL, ImageBiersafeM, ImageBiersafeS]
  const [currentImage, setCurrentImage] = useState(0)

  const switchImage = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1)
    } else {
      setCurrentImage(0)
    }
    return currentImage
  }

  useEffect(() => {
    const interval = setInterval(() => {
      switchImage()
    }, 1500)
    return () => clearInterval(interval)
  }, [currentImage])

  const Column = useMemo(
    () => ({ image, text, alt }) => {
      return (
        <div className='column'>
          <div className='image'>
            <Img
              className='image-shadow'
              style={{ transform: `scale(1)` }}
              fluid={image}
              alt={alt}
            />
          </div>

          <p>{text}</p>
        </div>
      )
    },
    []
  )

  return (
    <section id='sizes'>
      <Container>
        <Row className='row-anim'>
          <Col className='col-anim  ' md={6}>
            <div className='anim-empty'></div>
            <div
              className={`anim-box card-shadow-xl ${(currentImage === 0 &&
                'small') ||
                (currentImage === 1 && 'medium') ||
                (currentImage === 2 && 'large')}`}
            >
              <div className='image'>
                <Img
                  className={`d-none ${currentImage === 0 && 'd-block'}`}
                  fluid={imageBiersafeS.childImageSharp.fluid}
                  alt={intl.formatMessage({ id: 'home.sizes.imageBiersafeS' })}
                />
                <Img
                  className={`d-none ${currentImage === 1 && 'd-block'}`}
                  fluid={imageBiersafeM.childImageSharp.fluid}
                  alt={intl.formatMessage({ id: 'home.sizes.imageBiersafeM' })}
                />
                <Img
                  className={`d-none ${currentImage === 2 && 'd-block'}`}
                  fluid={imageBiersafeL.childImageSharp.fluid}
                  alt={intl.formatMessage({ id: 'home.sizes.imageBiersafeL' })}
                />
              </div>
            </div>
          </Col>
          <Col className='col-text ' md={6}>
            <div className='text-block'>
              <Scroll>
                <h2>
                  <FormattedHTMLMessage id='home.sizes.title' />
                </h2>
              </Scroll>
              <Scroll>
                <Button asElement={Link} to='/produkt/biersafe' fullwidth>
                  <FormattedMessage id='home.sizes.button' />
                </Button>
              </Scroll>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <div className='svg-correct d-block d-md-none'>
          <svg>
            <clipPath
              id='clip-path-sizes-mobile'
              clipPathUnits='objectBoundingBox'
            >
              <path d='M1,0.022 L0.96,0.017 L0.829,0.022 L0.678,0.013 C0.678,0.013,0.551,0.026,0.495,0.027 C0.439,0.028,0.337,0.025,0.337,0.025 L0.168,0 C0.168,0,0.063,0.001,0.029,0.001 L0,0.003 L0,1 L0.044,0.998 L0.057,0.997 H0.101 L0.15,0.994 L0.19,0.992 L0.246,0.99 L0.316,0.989 L0.373,0.99 L0.43,0.988 H0.521 L0.575,0.99 L0.599,0.991 L0.644,0.993 L0.702,0.99 L0.734,0.991 L0.755,0.992 L0.782,0.992 L0.792,0.991 H0.809 L0.86,0.992 L0.92,0.992 L0.95,0.993 L1,0.994 V0.022'></path>
            </clipPath>
          </svg>
        </div>
        <div className='svg-correct d-none d-md-block'>
          <svg>
            <clipPath id='clip-path-sizes' clipPathUnits='objectBoundingBox'>
              <path d='M1,0.065 L0.96,0.05 L0.829,0.065 L0.678,0.038 C0.678,0.038,0.551,0.077,0.495,0.08 C0.439,0.083,0.337,0.073,0.337,0.073 L0.168,0 C0.168,0,0.063,0.004,0.029,0.004 L0,0.007 L0,1 L0.044,0.994 L0.057,0.99 H0.101 L0.15,0.984 L0.19,0.976 L0.246,0.971 L0.316,0.967 L0.373,0.971 L0.43,0.966 H0.521 L0.575,0.97 L0.599,0.974 L0.644,0.978 L0.702,0.971 L0.734,0.974 L0.755,0.976 L0.782,0.976 L0.792,0.974 H0.809 L0.86,0.976 L0.92,0.976 L0.95,0.978 L1,0.984 V0.065'></path>
            </clipPath>
          </svg>
        </div>
        <div className='shadow-wrap'>
          <div className='background clipped shadow-wrap'>
            <Container>
              <div className='info'>
                <hr />
                <Row className='row-info'>
                  <div className='stripe'>
                    <img src={ImageStripe} alt='' />
                  </div>

                  <Col md={4}>
                    <Column
                      text={intl.formatMessage({
                        id: 'home.sizes.featureText1'
                      })}
                      image={imageScaffold.childImageSharp.fluid}
                      alt={intl.formatMessage({
                        id: 'home.sizes.featureImage1'
                      })}
                    />
                  </Col>
                  <Col md={4}>
                    <Column
                      text={intl.formatMessage({
                        id: 'home.sizes.featureText2'
                      })}
                      image={imageFangtechnik.childImageSharp.fluid}
                      alt={intl.formatMessage({
                        id: 'home.sizes.featureImage2'
                      })}
                    />
                  </Col>
                  <Col md={4}>
                    <Column
                      text={intl.formatMessage({
                        id: 'home.sizes.featureText3'
                      })}
                      image={imageZubehoer.childImageSharp.fluid}
                      alt={intl.formatMessage({
                        id: 'home.sizes.featureImage3'
                      })}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Sizes
