import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import IconBlob from '~/images/icon-blob.svg'
import {
  useIntl,
  FormattedMessage,
  FormattedHTMLMessage
} from 'gatsby-plugin-intl'
import { Parallax } from 'react-parallax'

import './testimonial.scss'

const Testimonial = () => {
  const intl = useIntl()
  const { imageTestimonial } = useStaticQuery(graphql`
    query {
      imageTestimonial: file(relativePath: { eq: "image-testimonial.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 670) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section id='testimonial'>
      <Container>
        <Row>
          <Col className='col-text' md={6}>
            <div>
              <p>
                <FormattedHTMLMessage id='home.testimonial.quote' />
              </p>
              <span className='author'>
                <FormattedMessage id='home.testimonial.author' />
              </span>
            </div>
          </Col>
          <Col md={6}>
            <div className='col-image'>
              <Parallax
                renderLayer={percentage => (
                  <Img
                    className='image card-shadow-xl'
                    style={{
                      transform: `translateY(${25 - percentage * 25}%)`
                    }}
                    alt={intl.formatMessage({ id: 'home.testimonial.image' })}
                    fluid={imageTestimonial.childImageSharp.fluid}
                  />
                )}
              />
              <img className='blob' src={IconBlob} alt='' />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Testimonial
