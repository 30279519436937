import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import IconCircleCheck from '~/images/icon-circle-check.svg'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import './intro.scss'
import { Scroll } from '~/components/00-global/Animation/Animation'

const ListItem = ({ text }) => {
  return (
    <li>
      <Scroll>
        <img src={IconCircleCheck} alt='' />
        {text}
      </Scroll>
    </li>
  )
}
const Intro = () => {
  const intl = useIntl()
  const { imageIntroHole, imageIntroBackground } = useStaticQuery(graphql`
    query {
      imageIntroHole: file(relativePath: { eq: "image-intro-hole.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageIntroBackground: file(
        relativePath: { eq: "image-intro-background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id='intro' className='section-margin'>
      <div className='svg-correct'>
        <svg>
          <clipPath id='my-clip-path' clipPathUnits='objectBoundingBox'>
            <path d='M1,0.92 L0.95,0.944 L0.92,0.953 L0.879,0.953 H0.86 L0.809,0.963 H0.792 L0.782,0.957 L0.755,0.955 L0.734,0.963 L0.702,0.978 L0.644,0.944 L0.599,0.963 L0.575,0.981 L0.521,1 H0.43 L0.373,0.98 L0.316,0.998 L0.246,0.98 L0.19,0.953 L0.15,0.92 L0.101,0.89 H0.057 L0.044,0.87 L0,0.844 V0 H1 L1,0.92'></path>
          </clipPath>
        </svg>
      </div>
      <div className='shadow-wrap'>
        <BackgroundImage
          className='background clipped'
          fluid={imageIntroBackground.childImageSharp.fluid}
        >
          <Container>
            <Row>
              <Col md={6}></Col>
              <Col lg={6} className='intro-content'>
                <Scroll>
                  <h2>
                    <FormattedMessage id='home.intro.title' />
                  </h2>
                </Scroll>

                <ul>
                  <ListItem
                    text={intl.formatMessage({ id: 'home.intro.list.0' })}
                  />
                  <ListItem
                    text={intl.formatMessage({ id: 'home.intro.list.1' })}
                  />
                  <ListItem
                    text={intl.formatMessage({ id: 'home.intro.list.2' })}
                  />
                  <ListItem
                    text={intl.formatMessage({ id: 'home.intro.list.3' })}
                  />
                </ul>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>
      </div>

      <div className='row-transform'>
        <Container>
          <Row>
            <Col md={6} className='col-left'>
              <div className='image card-shadow-l'>
                <Img
                  fluid={imageIntroHole.childImageSharp.fluid}
                  alt={intl.formatMessage({ id: 'home.intro.image' })}
                />
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default Intro
