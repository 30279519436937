//dependencies
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import {
  useIntl,
  Link,
  FormattedMessage,
  FormattedHTMLMessage
} from 'gatsby-plugin-intl'

//components
import Button from '~/components/00-global/Button/Button'

//styling
import { Container, Row, Col } from 'react-bootstrap'
import './hero.scss'
import { Scroll } from '~/components/00-global/Animation/Animation'

const Hero = () => {
  const intl = useIntl()
  const { imageHeroBackgroundWide, imageHeroPeople } = useStaticQuery(graphql`
    query {
      imageHeroBackgroundWide: file(
        relativePath: { eq: "image-hero-background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageHeroPeople: file(relativePath: { eq: "image-hero-people.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 490) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id='hero'>
      <div className='svg-correct'>
        <svg>
          <clipPath id='clip-path-hero' clipPathUnits='objectBoundingBox'>
            <path d='M0,0.82 L0.05,0.852 L0.08,0.865 L0.121,0.865 H0.14 L0.191,0.877 H0.208 L0.218,0.869 L0.245,0.867 L0.266,0.877 L0.298,0.897 L0.333,0.926 L0.378,0.951 L0.402,0.975 L0.456,1 H0.547 L0.604,0.973 L0.684,0.924 L0.754,0.899 L0.81,0.865 L0.85,0.82 L0.899,0.781 H0.943 L0.956,0.754 L1,0.72 V0 H0 V0.82'></path>
          </clipPath>
        </svg>
        <div className='shadow-wrap'>
          <BackgroundImage
            className='background clipped'
            alt={intl.formatMessage({ id: 'home.hero.imageBackground' })}
            // Wenn image source changes also change alt text
            fluid={imageHeroBackgroundWide.childImageSharp.fluid}
          >
            <Container>
              <Row>
                <Col className='col-text' md={6}>
                  <div className='text-content'>
                    <Scroll delay={400}>
                      <h1>
                        <FormattedHTMLMessage id='home.hero.title' />
                      </h1>
                    </Scroll>
                    <Scroll delay={600}>
                      <p>
                        <FormattedMessage id='home.hero.subtitle' />
                      </p>
                    </Scroll>
                    <Scroll delay={800}>
                      <Button asElement={Link} to='/produkt/biersafe' fullwidth>
                        <FormattedMessage id='home.hero.button' />
                      </Button>
                    </Scroll>
                  </div>
                </Col>
                <Col md={6}></Col>
              </Row>
            </Container>
          </BackgroundImage>
        </div>
      </div>
      <div className='row-transform'>
        <Container>
          <Row>
            <Col md={6}></Col>
            <Col md={6} className='col-right'>
              <div className='image img-border-radius'>
                <Img
                  // Wenn image source changes also change alt text
                  fluid={imageHeroPeople.childImageSharp.fluid}
                  alt={intl.formatMessage({ id: 'home.hero.image' })}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default Hero
