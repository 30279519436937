import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './shipping.scss'
import IconDelivery from '~/images/icon-delivery.svg'
import IconPayments from '~/images/icon-payments.svg'
import IconSafe from '~/images/icon-safe.svg'
import { FormattedHTMLMessage } from 'gatsby-plugin-intl'
import Underline from '~/components/00-global/Underline/Underline'
import { Fade, FadeDown } from '~/components/00-global/Animation/Animation'

const Shipping = () => {
  const Column = ({ icon, text }) => {
    return (
      <Fade>
        <div className='column'>
          <div className='symbol card-shadow-s'>
            <img src={icon} alt='' />
          </div>
          <FadeDown>
            <p>{text}</p>
          </FadeDown>
        </div>
      </Fade>
    )
  }
  return (
    <section id='shipping'>
      <Container>
        <Row>
          <Col md={4}>
            <Column
              icon={IconDelivery}
              text={
                <span>
                  <FormattedHTMLMessage id='home.shipping.text.0' />
                </span>
              }
            />
          </Col>
          <Col md={4}>
            <Column
              icon={IconSafe}
              text={
                <span>
                  <FormattedHTMLMessage id='home.shipping.text.1' />
                </span>
              }
            />
          </Col>
          <Col md={4}>
            <Column
              icon={IconPayments}
              text={
                <span>
                  <FormattedHTMLMessage id='home.shipping.text.2' />
                </span>
              }
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Shipping
