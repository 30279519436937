import React from 'react'
import { Container } from 'react-bootstrap'
import IconZdf from '~/images/icon-zdf.svg'
import IconSat1 from '~/images/icon-sat1.svg'
import IconRtl from '~/images/icon-rtl.svg'
import IconHgtv from '~/images/icon-hgtv.svg'
import IconWdr from '~/images/icon-wdr.svg'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import './knownfrom.scss'
import { Scroll } from '~/components/00-global/Animation/Animation'

const KnownFrom = () => {
  const intl = useIntl()
  return (
    <section id='knownfrom'>
      <Container>
        <Scroll>
          <h2>
            <FormattedMessage id='home.knownfrom.title' />
          </h2>
        </Scroll>
        <div className='logos'>
          <img
            src={IconZdf}
            alt={intl.formatMessage({ id: 'home.knownfrom.logos.0' })}
          />
          <img
            src={IconWdr}
            alt={intl.formatMessage({ id: 'home.knownfrom.logos.1' })}
          />
          <img
            src={IconRtl}
            alt={intl.formatMessage({ id: 'home.knownfrom.logos.2' })}
          />
          <img
            src={IconHgtv}
            alt={intl.formatMessage({ id: 'home.knownfrom.logos.3' })}
          />
          <img
            src={IconSat1}
            alt={intl.formatMessage({ id: 'home.knownfrom.logos.4' })}
          />
        </div>
      </Container>
    </section>
  )
}

export default KnownFrom
