import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

// Components
import SEO from '~/components/00-global/util/seo'
import Hero from '~/components/01-landing/hero/hero'
import Intro from '~/components/01-landing/intro/intro'
import KnownFrom from '~/components/01-landing/knownfrom/knownfrom'
import Grass from '~/components/01-landing/grass/grass'
import Party from '~/components/01-landing/party/party'
import Testimonial from '~/components/01-landing/testimonial/testimonial'
import Sizes from '~/components/01-landing/sizes/sizes'
import Handmade from '~/components/01-landing/handmade/handmade'
import Highlight from '~/components/01-landing/highlight/highlight'
import Rabatt from '~/components/01-landing/rabatt/rabatt'
import Shipping from '~/components/01-landing/shipping/shipping'
import { Load } from '~/components/00-global/Animation/Animation'

const IndexPage = ({ data }) => {
  const intl = useIntl()
  const coupon = data.allContentfulCoupon.nodes.filter(
    coupon => coupon.node_locale === intl.locale
  )[0]

  return (
    <>
      <Load>
        <SEO
          title='Home'
          description={intl.formatMessage({ id: 'home.hero.subtitle' })}
        />
        <Hero />
        <Intro />
        <KnownFrom />
        <Grass />
        <Party />
        <Testimonial />
        <Sizes />
        <Handmade />
        <Highlight />
        {coupon.show && <Rabatt text={coupon.text} code={coupon.code} />}
        <Shipping />
      </Load>
    </>
  )
}

export const query = graphql`
  query {
    allContentfulCoupon {
      nodes {
        show
        text
        code
        node_locale
      }
    }
  }
`

export default IndexPage
