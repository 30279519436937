import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Button from '~/components/00-global/Button/Button'
import './highlight.scss'
import {
  FormattedMessage,
  FormattedHTMLMessage,
  Link
} from 'gatsby-plugin-intl'
import { Scroll } from '~/components/00-global/Animation/Animation'

const Highlight = () => {
  const { imageHighlightBackground } = useStaticQuery(graphql`
    query {
      imageHighlightBackground: file(
        relativePath: { eq: "image-highlight-background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id='highlight' className='section-margin'>
      <div className='svg-correct'>
        <svg>
          <clipPath id='clip-path-highlight' clipPathUnits='objectBoundingBox'>
            <path d='M0.95,0.021 L1,0.016 V0.067 V0.933 V1 L0.956,0.995 L0.943,0.99 H0.899 L0.85,0.984 L0.81,0.977 L0.754,0.972 L0.684,0.968 L0.627,0.972 L0.57,0.968 H0.479 L0.425,0.971 L0.401,0.975 L0.356,0.979 L0.298,0.972 L0.266,0.975 L0.245,0.977 L0.218,0.977 L0.208,0.975 H0.191 L0.14,0.977 L0.08,0.977 L0.05,0.979 L0,0.984 V0.933 V0.067 V0 L0.044,0.005 L0.057,0.01 H0.101 L0.15,0.016 L0.19,0.023 L0.246,0.028 L0.316,0.032 L0.373,0.028 L0.43,0.032 H0.521 L0.575,0.029 L0.599,0.025 L0.644,0.021 L0.702,0.028 L0.734,0.025 L0.755,0.023 L0.782,0.023 L0.792,0.025 H0.809 L0.86,0.023 L0.92,0.023 L0.95,0.021'></path>
          </clipPath>
        </svg>
      </div>
      <div className='shadow-wrap'>
        <BackgroundImage
          className='background clipped'
          fluid={imageHighlightBackground.childImageSharp.fluid}
          style={{ backgroundPosition: '50% 20%' }}
        >
          <Container>
            <Row>
              <Col>
                <Scroll>
                  <p className='p-xxl'>
                    <FormattedMessage id='home.highlight.subtitle' />
                  </p>
                </Scroll>
                <Scroll>
                  <h2 className='h2-xxl'>
                    <FormattedHTMLMessage id='home.highlight.title' />
                  </h2>
                </Scroll>
                <Scroll>
                  <Button asElement={Link} to='/produkt/biersafe' fullwidth>
                    <FormattedMessage id='home.highlight.button' />
                  </Button>
                </Scroll>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>
      </div>
    </section>
  )
}

export default Highlight
