import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import Button from '~/components/00-global/Button/Button'
import ImageMaskGrassBottom from '~/images/image-mask-grass-bottom.svg'
import { Parallax } from 'react-parallax'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'
import './grass.scss'
import { Scroll } from '~/components/00-global/Animation/Animation'
import styleVariables from '~/layouts/variables.scss'

import GetWindowDimensions from '~/components/00-global/util/getWindowDimensions'

const Grass = () => {
  const intl = useIntl()
  const xxl =
    typeof document !== `undefined` ? styleVariables.xxl.replace(/\D+/, '') : ''
  const md =
    typeof document !== `undefined` ? styleVariables.md.replace(/\D+/, '') : ''
  const { windowWidth } = GetWindowDimensions()
  const {
    imageSummer,
    imageWinter,
    imageGrassLow,
    imageGrassMobile,
    imageComic,
    imageGrassBackground,
    imageBiersafeTube,
    imageBiersafeScaffold
  } = useStaticQuery(graphql`
    query {
      imageBiersafeScaffold: file(
        relativePath: { eq: "image-biersafe-scaffold.png" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageBiersafeTube: file(relativePath: { eq: "image-biersafe-tube.png" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageGrassLow: file(relativePath: { eq: "image-grass-medium.png" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageGrassMobile: file(relativePath: { eq: "image-grass-mobile.png" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageComic: file(relativePath: { eq: "image-comic.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 520) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageGrassBackground: file(
        relativePath: { eq: "image-intro-background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSummer: file(relativePath: { eq: "image-man-on-field.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 620) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageWinter: file(relativePath: { eq: "image-biersafe-man-snow.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 620) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const getSeason = () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()

    const winterStart = new Date(`${currentYear}-11-15`)
    const summerStart = new Date(`${currentYear}-2-28`)

    if (currentDate >= winterStart || currentDate <= summerStart) {
      return 'winter'
    } else {
      return 'summer'
    }
  }

  return (
    <section id='grass'>
      <Container>
        <Row>
          <Col className='col-text' md={6}>
            <div className='text-block'>
              <Scroll>
                <h2>
                  <FormattedMessage id='home.grass.title' />
                </h2>
              </Scroll>
              <Scroll>
                <p>
                  <FormattedMessage id='home.grass.text' />
                </p>
              </Scroll>
            </div>
          </Col>

          <Col className='col-image' md={6}>
            <div className='image'>
              <Img
                className='card-shadow-m'
                fluid={imageComic.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'home.grass.image' })}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <div className='svg-correct'>
        <svg>
          <clipPath id='clip-path-grass-top' clipPathUnits='objectBoundingBox'>
            <path d='M0,0.979 L0.051,0.966 L0.124,0.979 L0.192,0.956 L0.243,1 L0.3,0.985 L0.353,1 L0.399,0.963 L0.477,0.985 L0.544,0.963 L0.617,0.945 L0.682,0.963 L0.758,0.945 L0.82,0.979 L0.882,0.945 L0.925,0.979 L0.968,0.937 L1,0.96 V0.004 H0 V0.979'></path>
          </clipPath>
        </svg>
      </div>

      <div className='row-parallax'>
        <Container>
          <Row>
            <Col md={6}></Col>
            <Col md={6}></Col>
          </Row>
        </Container>
        <div className='shadow-wrap'>
          <div className='clipped-grass-top'>
            <Img
              className='d-none d-md-block'
              fluid={imageGrassLow.childImageSharp.fluid}
            />
            <Img
              className='d-block d-md-none'
              fluid={imageGrassMobile.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
      {/* <svg>
				<clipPath id='clip-path-grass-bottom' clipPathUnits='objectBoundingBox'>
					<path d='M0,0 H1 V0.818 L0.956,0.84 L0.943,0.858 H0.899 L0.85,0.883 L0.81,0.912 L0.754,0.935 L0.684,0.951 L0.604,0.983 L0.547,1 H0.456 L0.402,0.984 L0.378,0.968 L0.333,0.952 L0.298,0.933 L0.266,0.92 L0.245,0.914 L0.218,0.915 L0.208,0.92 H0.191 L0.14,0.912 H0.121 L0.08,0.912 L0.05,0.904 L0,0.883 V0'></path>
				</clipPath>
			</svg> */}

      <BackgroundImage
        className='background clipped-bottom'
        fluid={imageGrassBackground.childImageSharp.fluid}
      >
        <Container>
          <Row>
            <Col className='col-parallax' md={6}>
              <div className='parallax-container'>
                <div className='container-inner'>
                  <Parallax
                    renderLayer={percentage => (
                      <div
                        className='tube'
                        style={
                          windowWidth < md
                            ? {
                                transform: `translateY(${
                                  -120 + percentage * 110 >= 0
                                    ? 0
                                    : -120 + percentage * 110
                                }%)`
                              }
                            : windowWidth < xxl
                            ? {
                                transform: `translateY(${
                                  -120 + percentage * 88 >= 0
                                    ? 0
                                    : -120 + percentage * 88
                                }%)`
                              }
                            : {
                                transform: `translateY(${
                                  -120 + percentage * 130 >= 0
                                    ? 0
                                    : -120 + percentage * 130
                                }%)`
                              }
                        }
                      >
                        <Img fluid={imageBiersafeTube.childImageSharp.fluid} />
                        <Parallax
                          renderLayer={percentageInner => (
                            <div
                              className='scaffold'
                              style={{
                                transform: `translateY(${
                                  -80 + percentage * 100 >= 0
                                    ? 0
                                    : -80 + percentage * 100
                                }%)`
                              }}
                            >
                              <Img
                                fluid={
                                  imageBiersafeScaffold.childImageSharp.fluid
                                }
                              />
                            </div>
                          )}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className='text-block d-md-none'>
                <Scroll>
                  <h2>
                    <FormattedMessage id='home.grass.title2' />
                  </h2>
                </Scroll>
                <Scroll>
                  <p>
                    <FormattedMessage id='home.grass.text2' />
                  </p>
                </Scroll>

                <Scroll>
                  <div className='d-none d-s-inline'>
                    <Button asElement={Link} to='/infos' fullwidth>
                      <FormattedMessage id='home.grass.button' />
                    </Button>
                  </div>
                </Scroll>
              </div>
            </Col>
            <Col className='col-drink' md={6}>
              <div className='text-block d-none d-md-block'>
                <Scroll>
                  <h2>
                    <FormattedMessage id='home.grass.title2' />
                  </h2>
                </Scroll>
                <Scroll>
                  <p>
                    <FormattedMessage id='home.grass.text2' />
                  </p>
                </Scroll>
                <Scroll>
                  <Button asElement={Link} to='/infos'>
                    <FormattedMessage id='home.grass.button' />
                  </Button>
                </Scroll>
              </div>
              <Scroll>
                <div className='button-s d-s-none'>
                  <Button asElement={Link} to='/infos' fullwidth>
                    <FormattedMessage id='home.grass.button' />
                  </Button>
                </div>
              </Scroll>
            </Col>
          </Row>
        </Container>

        <img src={ImageMaskGrassBottom} alt='' className='mask-border-bottom' />
      </BackgroundImage>
      <Container>
        <Row>
          <Col md={6}></Col>
          <Col className='col-build ' md={6}>
            <div className='image-build card-shadow-l'>
              <Img
                fluid={
                  getSeason() === 'summer'
                    ? imageSummer.childImageSharp.fluid
                    : imageWinter.childImageSharp.fluid
                }
                alt={
                  getSeason() === 'summer'
                    ? intl.formatMessage({ id: 'home.grass.imageSummer' })
                    : intl.formatMessage({ id: 'home.grass.imageWinter' })
                }
              />
            </div>
            {getSeason() === 'winter' && (
              <Scroll>
                <div className='winter-text'>
                  <h2>
                    <FormattedMessage id='home.grass.titleWinter' />
                  </h2>
                  <p>
                    <FormattedMessage id='home.grass.textWinter' />
                  </p>
                </div>
              </Scroll>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Grass
