import React from 'react'
import { Container } from 'react-bootstrap'
import { FormattedMessage } from 'gatsby-plugin-intl'
import ImageStripeWhite from '~/images/image-stripe-white.svg'
import './rabatt.scss'

const Rabatt = ({ text, code }) => {
  return (
    <section id='rabatt' className='section-margin'>
      <Container>
        <div className='body card-shadow-l'>
          <h2>
            <FormattedMessage id='home.rabatt.title' />
          </h2>
          <h3>
            {text}
            <br className='d-block d-md-none' />
            <div className='highlight'>
              <p>{code}</p>
              <img src={ImageStripeWhite} alt='' />
            </div>
          </h3>
        </div>
      </Container>
    </section>
  )
}

export default Rabatt
