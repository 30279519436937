import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Button from '~/components/00-global/Button/Button'
import {
  useIntl,
  FormattedMessage,
  FormattedHTMLMessage,
  Link
} from 'gatsby-plugin-intl'
import './party.scss'
import { Scroll } from '~/components/00-global/Animation/Animation'

const Party = () => {
  const intl = useIntl()
  const { imagePartyPolaroid, imagePartyKonfetti } = useStaticQuery(graphql`
    query {
      imagePartyPolaroid: file(
        relativePath: { eq: "image-party-polaroid.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imagePartyKonfetti: file(
        relativePath: { eq: "image-party-konfetti.png" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section id='party'>
      <Container>
        <Row>
          <Col md={6} className='order-1 order-md-0'>
            <div className='image-party '>
              <Img
                fluid={imagePartyPolaroid.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'home.party.image' })}
              />
            </div>
          </Col>
          <Col className='col-konfetti' md={6}>
            <div className='col-content'>
              <div className='image-konfetti'>
                <Img fluid={imagePartyKonfetti.childImageSharp.fluid} />
              </div>
              <div className='text-block'>
                <Scroll>
                  <h2>
                    <FormattedHTMLMessage id='home.party.title' />
                  </h2>
                </Scroll>
                <Scroll>
                  <p>
                    <FormattedMessage id='home.party.text' />
                  </p>
                </Scroll>
                <Scroll>
                  <Button asElement={Link} to='/produkt/biersafe' fullwidth>
                    <FormattedMessage id='home.party.button' />
                  </Button>
                </Scroll>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Party
